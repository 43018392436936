<template>
  <div class="shopping-cart-table">
    <div v-if="this.cartItems.length" class="shopping-cart-table-wrap">
      <b-modal
        id="product-delete"
        class="product-modal-inner"
        centered
        hide-footer
        content-class="defaul-modal"
        size="xl"
      >
        <template #modal-title class="bold"
          >{{ $t("remove_product_cart") }}
        </template>
        <div class="modal-text d-block border-bottom">
          <p>
            {{ $t("sure_remove_product_cart") }}
          </p>
        </div>
        <div class="modal-btn-box pt-20">
          <b-button
            class="btn-success"
            block
            @click="
              $bvModal.hide('product-delete');
              removeItem(productID);
            "
            >Ok</b-button
          >
          <b-button
            class="btn-success btn-outline"
            block
            @click="$bvModal.hide('product-delete')"
            >{{ $t("cancel") }}</b-button
          >
        </div>
      </b-modal>
      <div class="shopping-cart-top">
        <table>
          <tr>
            <th>{{ $t("product_name") }}</th>
            <th>{{ $t("Price") }}</th>
            <th>{{ $t("unit") }}</th>
            <th>{{ $t("no_of") }}</th>
            <th>{{ $t("subtotal") }}</th>
          </tr>
          <tr
            v-for="(cartItem, index) of cartItemsWithBackLink"
            :key="cartItem.id"
          >
            <td>
              <div class="shopping-pro-info">
                <div class="shopping-pro-info-wrap">
                  <div class="icn-img-wrap">
                    <span class="trash-icon">
                      <b-link class="pr-1">
                        <font-awesome-icon
                          icon="trash-alt"
                          id="show-btn"
                          @click="
                            $bvModal.show('product-delete');
                            removeProduct(cartItem.id);
                          "
                        />
                      </b-link>
                    </span>
                    <span class="shopping-pro-img">
                      <b-link
                        :to="'/' + cartItem.product.url_key + '/'"
                        v-if="cartItem.product.sku != 'Kleurstaal'"
                      >
                        <b-img
                          :src="`${cartItem.product.image.url}`"
                          alt="img"
                        />
                      </b-link>
                      <b-link
                        v-else-if="
                          backLink[index] && backLink[index].items.image
                        "
                        :to="'/' + backLink[index].items.url_key + '/'"
                      >
                        <b-img
                          :src="
                            `${
                              backLink[index].items.image.x_small
                                ? backLink[index].items.image.x_small
                                : cartItem.product.image.url
                            }`
                          "
                          alt="img"
                        />
                      </b-link>
                    </span>
                  </div>
                  <div class="shopping-pro-des">
                    <b-link
                      :to="'/' + cartItem.product.url_key + '/'"
                      v-if="cartItem.product.sku != 'Kleurstaal'"
                    >
                      <p class="shopping-pro-name">
                        {{ cartItem.product.name }}
                      </p>
                    </b-link>
                    <b-link
                      v-else-if="
                        backLink[index] && backLink[index].items.url_key
                      "
                      :to="'/' + backLink[index].items.url_key + '/'"
                    >
                      <p class="shopping-pro-name">
                        {{ cartItem.product.name }}
                      </p>
                    </b-link>
                    <p
                      class="shopping-pro-offer-tag"
                      :class="{
                        'd-none':
                          cartItem.product.price_range.maximum_price.discount
                            .percent_off == 0,
                      }"
                    >
                      <b-link class="offer-link">
                        -&nbsp;{{
                          cartItem.product.price_range.maximum_price.discount.percent_off.toFixed(
                            0
                          )
                        }}%
                      </b-link>
                    </p>
                    <div
                      class="shopping-pro-point"
                      v-for="(option, index) in getCartItemBundleOptions(
                        cartItem
                      )"
                      :key="index"
                    >
                      <p>
                        {{ option.label }}:
                        {{ option.values[0].label }}
                      </p>
                    </div>
                    <div
                      class="shopping-pro-point"
                      v-for="(option, index) in getCartItemConfigurableOptions(
                        cartItem
                      )"
                      :key="index"
                    >
                      <p>{{ option.option_label }}: {{ option.value_label }}</p>
                    </div>
                    <div
                      class="shopping-pro-point"
                      v-for="(option, index) in getCartItemSimpleOptions(
                        cartItem
                      )"
                      :key="index"
                    >
                      <p v-if="option.values[0].label">
                        {{ option.values[0].label }}:
                        {{ option.values[0].value }}
                      </p>
                      <p v-else>{{ option.values[0].value }}</p>
                    </div>
                    <div
                      class="shopping-pro-point"
                      v-for="(option, index) in kleurstaalDataMap[
                        cartItem.id
                      ] || []"
                      :key="index"
                    >
                      <p v-if="index == 0">
                        <b>Artikelnummer</b>:
                        {{ option.values[0].value }}
                      </p>
                      <p v-else-if="index == 1">
                        <b>Artikel</b>: {{ option.values[0].value }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text">
              {{ formatCurrency(cartItem.prices.price_incl_tax.value) }}
            </td>

            <td>
              <span v-if="cartItem.product.unit">
                {{ cartItem.product.unit }}
              </span>
            </td>
            <td>
              <div class="shopping-pro-stock">
                <div class="number-count-inner">
                  <span class="number-count">
                    <input
                      class="stock-count"
                      type="number"
                      id="tentacles"
                      name="quantity"
                      ref="inputQuantity"
                      :placeholder="`${cartItem.quantity}`"
                      min="1"
                      max="100"
                      :value="localQuantities[cartItem.id] || cartItem.quantity"
                      @input="updateLocalQuantity($event, cartItem.id)"
                      @blur="changeQuantity($event, cartItem.id)"
                    />
                  </span>
                  <span class="number-countarrow">
                    <div class="count-up">
                      <font-awesome-icon
                        icon="chevron-up"
                        @click="quantityUp(cartItem.id, cartItem.quantity)"
                      />
                    </div>
                    <div class="count-down">
                      <font-awesome-icon
                        icon="chevron-down"
                        @click="quantityDown(cartItem.id, cartItem.quantity)"
                      />
                    </div>
                  </span>
                </div>
              </div>
            </td>
            <td class="text">
              {{
                formatCurrency(cartItem.prices.row_total_including_tax.value)
              }}
            </td>
          </tr>
        </table>
      </div>

      <div class="shopping-cart-bottom">
        <div class="subtotal">
          <label
            >{{ $t("subtotal") }}:
            {{ formatCurrency(cartSubTotalPrice) }}</label
          >
        </div>
        <div class="cart-bottom-inner">
          <div class="row">
            <div class="col-xs-12">
              <div class="cart-total-text">
                <div class="width-20">
                  <div class="second" v-if="shippingMethod != null">
                    {{ $t("shipping_cost_cart") }}
                  </div>
                  <div class="third">
                    <label
                      >{{ $t("total") }}
                      {{ formatCurrency(cartTotalPrice) }}</label
                    >
                    <p v-if="cartTotalTaxPrices.length > 0">
                      {{
                        $t("including_21_vat", {
                          amount: formatCurrency(
                            cartTotalTaxPrices[0].amount.value
                          ),
                        })
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div class="cart-button-left">
                <div class="shopping-pro-cart-wrap" v-if="!isMobile">
                  <div>
                    <b-link
                      @click="$router.go(-1)"
                      class="popup-link bg-primary d-flex"
                    >
                      <span
                        ><font-awesome-icon icon="chevron-circle-left"
                      /></span>
                      {{ $t("continue_shopping") }}
                    </b-link>
                  </div>
                  <div class="d-inline-flex">
                    <b-link
                      class="popup-link bg-warning d-flex"
                      @click.prevent="addCartToWishlist()"
                    >
                      {{ $t("save_shopping_cart") }}
                      <span><font-awesome-icon icon="check-circle"/></span>
                    </b-link>
                    <b-link to="/order/" class="popup-link btn-success d-flex">
                      {{ $t("goto_checkout") }}
                      <span
                        ><font-awesome-icon icon="chevron-circle-right"
                      /></span>
                    </b-link>
                  </div>
                </div>
                <div class="shopping-pro-cart-wrap mobile" v-else>
                  <div :class="{ sticky: isSticky }">
                    <b-link
                      id="continueShopping"
                      @click="$router.go(-1)"
                      class="popup-link bg-primary d-flex"
                    >
                      <span
                        ><font-awesome-icon icon="chevron-circle-left"
                      /></span>
                      {{ $t("continue_shopping") }}
                    </b-link>
                  </div>
                  <div class="d-inline-flex">
                    <b-link
                      class="popup-link bg-warning d-flex"
                      @click.prevent="addCartToWishlist()"
                    >
                      {{ $t("save_shopping_cart") }}
                      <span><font-awesome-icon icon="check-circle"/></span>
                    </b-link>
                    <b-link
                      to="/order/"
                      class="popup-link btn-success d-flex"
                      id="goToCheckout"
                    >
                      {{ $t("goto_checkout") }}
                      <span
                        ><font-awesome-icon icon="chevron-circle-right"
                      /></span>
                    </b-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="no-data-found">{{ $t("no_products_in_cart") }}</div>
    </div>
    <div class="shopping-cart-slider" v-if="crossSellProducts.length">
      <div class="shop-txt pb-25">
        <h3 class="page-heading">{{ $t("handy_order_with") }}</h3>
      </div>
      <VueSlickCarousel v-bind="settings_shopping">
        <div
          v-for="(crossSell, index) in crossSellProducts"
          :index="index"
          :key="index"
        >
          <div class="product-shopping">
            <ShoppingProductCardComponent :relatedProduct="crossSell" />
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div class="shopping-pro-testimonial">
      <ShoppingTestimonialComponent />
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import shoppingImg from "@/esf_utrecht_bankxl/assets/images/s-prd-img.jpg";
import shoppingImg1 from "@/esf_utrecht_bankxl/assets/images/s-prd-img1.jpg";
import ShoppingProductCardComponent from "@/esf_utrecht_bankxl/core/components/shopping-cart/ShoppingProductCardComponent";
import ShoppingTestimonialComponent from "@/esf_utrecht_bankxl/core/components/shopping-cart/ShoppingTestimonialComponent";
import Cart from "@storefront/core/modules/cart/mixins";
import { isMobile } from "mobile-device-detect";
import { isServer } from "@storefront/core/helpers";

export default {
  name: "ShoppingCartProductComponent",
  components: {
    VueSlickCarousel,
    ShoppingProductCardComponent,
    ShoppingTestimonialComponent,
  },
  mixins: [Cart],
  data() {
    return {
      shoppingImg,
      shoppingImg1,
      productID: "",
      // second slider
      settings_shopping: {
        centerMode: false,
        dots: false,
        infinite: true,
        speed: 500,
        controls: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        adaptiveHeight: true,
        rtl: false,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 577,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      crossSellLoaded: false,
      isSticky: false,
      lastScrollPosition: 0,
      localQuantities: {},
    };
  },
  computed: {
    isShowCartItem() {
      return this.cartItems && this.cartItems.length ? true : false;
    },
    cartItemsWithBackLink() {
      return this.cartItems.map((cartItem, index) => ({
        ...cartItem,
        backLink: this.backLink[index] ? this.backLink[index] : null,
      }));
    },
    kleurstaalDataMap() {
      return this.cartItems.reduce((acc, cartItem) => {
        acc[cartItem.id] = this.getKleurstaalData(cartItem);
        return acc;
      }, {});
    },

    cartTotalWithOutTax() {
      if (this.$store.getters["cart/getCartIsLoaded"] == true) {
        const prices = this.$store.getters["cart/getCartPrices"];
        if (typeof prices != "undefined") {
          if (typeof prices.subtotal_excluding_tax != "undefined") {
            if (typeof prices.subtotal_excluding_tax.value != "undefined") {
              return prices.subtotal_excluding_tax.value;
            }
          }
        }
      }
      return 0;
    },

    crossSellProducts: {
      get() {
        var products = this.$store.getters["product/getCrossSell"];
        if (products && products.length == 0) {
          if (!isServer) {
            var localData = JSON.parse(localStorage.getItem("crossSell"));
            if (localData && localData.length > 0) {
              products = localData;
            }
          }
        }
        return products;
      },
      set() {},
    },
    shippingMethod() {
      const selectedShipping = this.$store.getters["cart/getShippingMethod"];
      if (selectedShipping) return selectedShipping;
      return this.defaultShipping;
    },
    isMobile() {
      return isMobile;
    },
    backLink() {
      return this.$store.getters["productSerie/getBackLink"];
    },
  },
  methods: {
    // Store the input value in localQuantities
    updateLocalQuantity(event, id) {
      this.localQuantities = {
        ...this.localQuantities,
        [id]: event.target.value,
      };
    },
    changeQuantity(event, id) {
      const qty = event.target.value;
      if (qty > 0) {
        this.$store.dispatch("cart/updateCartItem", {
          id: id,
          quantity: event.target.value,
        });
      } else {
        this.$store.dispatch("cart/updateCartItem", {
          id: id,
          quantity: 1,
        });
      }
      this.updateCrossSell();
    },
    removeProduct(productId) {
      this.productID = productId;
      this.updateCrossSell();

      // Find index of the item being removed
      const itemIndex = this.cartItemsWithBackLink.findIndex(
        (cartItem) => cartItem.id === productId
      );

      if (itemIndex !== -1) {
        const cartItem = this.cartItemsWithBackLink[itemIndex];

        // Get the SKU to compare
        const removedSku =
          cartItem.customizable_options?.[0]?.values?.[0]?.value || null;

        // Filter backLink to remove only the matching SKU
        const updatedBackLink = this.backLink.filter((linkItem, index) => {
          if (index === itemIndex && linkItem.sku === removedSku) {
            return false;
          }
          return true;
        });
        const removedSkuData = { sku: removedSku, linkItem: cartItem };
        this.$store.commit("setBackLink", {
          items: updatedBackLink,
          sku: removedSku,
        });
        this.$store.commit("productSerie/resetBackLink", removedSkuData);
      }
    },
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
    addCartToWishlist() {
      this.cartItems.forEach((item) => {
        if (item.__typename == "SimpleCartItem") {
          this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: item.product.sku,
            parentSku: null,
          });
        } else if (item.__typename == "ConfigurableCartItem") {
          this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: item.configured_variant.sku,
            parentSku: item.product.sku,
          });
        } else {
          var entered_options = [];
          item.bundle_options.forEach((option) => {
            entered_options.push(option.uid);
          });
          this.$store.dispatch("wishlist/addProductToWishlist", {
            sku: item.product.sku,
            parentSku: null,
            entered_options: entered_options,
          });
        }
      });

      // Product Added to wishlist notification
      if (this.cartItems.length == 1) {
        const msg = {
          type: "success",
          text: this.$t("wishlist success"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      } else {
        const msg = {
          type: "success",
          text: this.$t("products_wishlist_success"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
    getCartItemBundleOptions(cartItem) {
      if (cartItem.__typename == "BundleCartItem") {
        return cartItem.bundle_options;
      } else {
        return [];
      }
    },
    getCartItemConfigurableOptions(cartItem) {
      if (cartItem.__typename == "ConfigurableCartItem") {
        return cartItem.configurable_options;
      } else {
        return [];
      }
    },
    getCartItemSimpleOptions(cartItem) {
      if (
        cartItem.__typename == "SimpleCartItem" &&
        cartItem.product.sku != "Kleurstaal"
      ) {
        return cartItem.customizable_options;
      } else {
        return [];
      }
    },
    getKleurstaalData(cartItem) {
      if (
        cartItem.__typename == "SimpleCartItem" &&
        cartItem.product.sku == "Kleurstaal"
      ) {
        this.getBacklink(cartItem.customizable_options[0].values[0].value);
        return cartItem.customizable_options;
      } else {
        return [];
      }
    },
    updateCrossSell() {
      let skuList = [];
      this.cartItems.forEach((cartItem) => {
        if (cartItem.product && cartItem.product.sku) {
          skuList.push(cartItem.product.sku);
        }
      });
      this.$store.dispatch("product/loadCrossSellForCart", { skus: skuList });
    },
    onScroll() {
      const rect = document.getElementById("continueShopping");
      if (!rect) return;
      let elementTop = rect.getBoundingClientRect().top;

      if (this.isSticky) {
        elementTop = elementTop + 50;
      }
      this.isSticky =
        elementTop < 100 || elementTop - screen.height > 25 ? true : false;
    },
    getBacklink(sku) {
      this.$store.dispatch("productSerie/loadBackLink", { sku: sku });
    },
  },
  mounted() {
    if (!isServer) {
      window.addEventListener("scroll", this.onScroll);
      var rect = document.getElementById("goToCheckout");
      if (rect && rect.offsetTop > screen.height) {
        this.isSticky = true;
      }
    }
    this.updateCrossSell();
  },
  watch: {
    crossSellProducts() {
      var products = this.crossSellProducts;
      if (products && products.length > 0) {
        localStorage.setItem(
          "crossSell",
          JSON.stringify(this.crossSellProducts)
        );
      }
    },
    cartItems() {
      if (
        this.cartItems.length > 0 &&
        this.crossSellProducts &&
        this.crossSellProducts.length == 0
      ) {
        this.updateCrossSell();
      }
      let timeOut;
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        this.onScroll();
      }, 100);
    },
  },
  beforeDestroy() {
    if (!isServer) {
      window.removeEventListener("scroll", this.onScroll);
    }
  },
};
</script>
