<template>
  <div class="product-page-bottom-left">
    <div v-if="downloads && downloads.length > 0" class="product-content left">
      <h3 class="product-heading category-heading">{{ $t("downloads") }}</h3>
      <div class="product-content-inner">
        <ul class="downlist">
          <li v-for="(item, idx) in downloads" :key="'downloads' + idx">
            <b-link :href="item.url" target="_blank">
              <span class="pdf-icon">
                <b-img alt :src="pdfIcon" />
              </span>
              <span>{{ item.name }}</span>
            </b-link>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="productDescription"
      class="product-content left"
      id="product-information"
    >
      <h3 class="product-heading category-heading">
        {{ $t("product_information") }}
      </h3>

      <div>
        <div
          class="product-content-inner productInformation"
          :class="{ readLess: !readMore.productDesc }"
          ref="attrInformation"
        >
          <div v-if="product.cashback_promotion" class="cashback-block">
            <div class="price">
              {{
                formatCurrency(product.cashback_promotion.amount.value) +
                  " cashback"
              }}
            </div>
            <div class="info" v-html="product.cashback_promotion.description" />
          </div>

          <div class="product-description" v-html="productDescription"></div>
        </div>
        <div class="read-more-less" v-if="productDescription.length > 60">
          <b-link
            :key="readMore.productDesc"
            v-if="!readMore.productDesc"
            @click="productDescriptionToggleReadMore"
          >
            {{ $t("read_more") }}
          </b-link>
          <b-link
            :key="readMore.productDesc"
            v-else
            @click="productDescriptionToggleReadMore"
          >
            {{ $t("read_less") }}
          </b-link>
        </div>
      </div>
    </div>

    <div class="product-content left" v-if="informationAttributes.length > 0">
      <h3 class="product-heading category-heading">
        {{ $t("product_attributes") }}
      </h3>

      <div>
        <div class="custom-table-product attrInformation">
          <b-table
            thead-class="d-none"
            striped
            hover
            :items="informationAttributes"
            :fields="['label', 'value']"
          ></b-table>
        </div>

        <p
          class="text-content read-more-less"
          v-if="informationAttributesLength > 15"
        >
          <b-link
            v-if="!readMore.productInfoAttrs"
            :key="readMore.productInfoAttrs"
            @click.prevent="productInfoToggleReadMore"
          >
            {{ $t("read_more") }}
          </b-link>
          <b-link
            v-else
            :key="readMore.productInfoAttrs"
            @click.prevent="productInfoToggleReadMore"
          >
            {{ $t("read_less") }}
          </b-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pdfIcon from "@/esf_utrecht_bankxl/assets/images/pdf-icon.png";

export default {
  name: "ProductBottomLeft",
  props: ["selectedConfigProduct"],
  data() {
    return {
      pdfIcon,
      originalScrollPosition: null,
      readMore: {
        productDesc: false,
        productInfoAttrs: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      related: "product/getRelated",
      serie: "productSerie/getSerie",
    }),
    downloads() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.downloads
        : this.product.downloads;
    },
    productDescription() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.short_description.html +
            this.selectedConfigProduct.description.html
        : this.product.short_description.html + this.product.description.html;
    },
    productShortDescription() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.short_description
        : this.product.short_description;
    },
    informationAttributesLength() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.information_attributes.length
        : this.product.information_attributes.length;
    },
    informationAttributes() {
      const infoAttrs = this.selectedConfigProduct
        ? this.selectedConfigProduct.information_attributes
        : this.product.information_attributes;

      if (!this.readMore.productInfoAttrs) {
        return infoAttrs.slice(0, 15);
      } else {
        if (this.$refs.attrInformation) {
          this.$refs.attrInformation.scrollIntoView({ behavior: "smooth" });
        }
      }
      return infoAttrs;
    },
  },
  methods: {
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount, false);
    },
    productDescriptionToggleReadMore(event) {
      event.target.blur();
      if (this.readMore.productDesc) {
        window.scrollTo(0, this.originalScrollPosition);
      } else {
        this.originalScrollPosition = window.scrollY;
      }
      this.readMore.productDesc = !this.readMore.productDesc;
      console.log(this.readMore.productDesc, "this.readMore.productDesc");
    },
    productInfoToggleReadMore(event) {
      event.target.blur();
      this.readMore.productInfoAttrs = !this.readMore.productInfoAttrs;
    },
  },
};
</script>

<style></style>
